let version = 'v1';

let config = {
    qiniuToken: `/${version}/common/oss/uploadToken`,
    // login
    // login by username
    loginByUserName: `/${version}/permission/login`,
    // login by mobile
    loginByMobile: `/${version}/permission/loginByMobile`,
    changeMobile: `/${version}/permission/changeMobile`,
    changePwd: `/${version}/permission/changePassword`,
    logout: `/${version}/permission/logout`,
    // send sms code for login
    mobileSMSCode: `/${version}/common/sms/send`,

    accountList: `/${version}/permission/user/lists`,
    accountDetail: `/${version}/permission/user/detail`,
    accountAdd: `/${version}/permission/user/add`,
    accountEdit: `/${version}/permission/user/edit`,
    accountChangeMobile: `/${version}/permission/user/changeMobile`,
    accountChangePwd: `/${version}/permission/user/changePassword`,
    accountDelete: `/${version}/permission/user/delete`,

    roleList: `/${version}/permission/role/lists`,
    roleDetail: `/${version}/permission/role/detail`,
    roleAdd: `/${version}/permission/role/add`,
    roleEdit: `/${version}/permission/role/edit`,
    roleDelete: `/${version}/permission/role/delete`,

    permissionList: `/${version}/permission/permission/lists`,
    permissionAdd: `/${version}/permission/permission/add`,
    permissionEdit: `/${version}/permission/permission/edit`,
    permissionDelete: `/${version}/permission/permission/delete`,

    // index 
    // student list
    studentList: `/${version}/student/student/lists`,

    studentEdit: `/${version}/student/student/edit`,
    studentAdd: `/${version}/student/student/add`,
    studentDelete: `/${version}/student/student/delete`,
    studentUpdateStatus: `/${version}/student/student/changeStatus`,
    studentImport: `/${version}/student/student/import`,
    studentTmp: `/${version}/student/student/downloadTmp`,

    findStudentByNumber: `/${version}/student/effective/studentList`,
    effectiveSave: `/${version}/student/effective/save`,
    effectiveList: `/${version}/student/effective/lists`,
    effectiveDelete: `/${version}/student/effective/delete`,
    effectiveUpdate: `/${version}/student/effective/update`,
    effectiveRuleList: `/${version}/student/effective/ruleList`,

    // 
    teacherList: `/${version}/teacher/teacher/lists`,
    teacherDelete: `/${version}/teacher/teacher/delete`,
    teacherUpdateStatus: `/${version}/teacher/teacher/changeStatus`,
    teacherImport: `/${version}/teacher/teacher/import`,
    teacherTmp: `/${version}/teacher/teacher/downloadTmp`,

    statisicAuth: `/${version}/run/statistic/auth`,
    runStudent: `/${version}/run/record/student`,


    // base config
    enroll: `/${version}/setting/enroll/lists`,
    enrollDetail: `/${version}/setting/enroll/detail`,
    enrollEdit: `/${version}/setting/enroll/edit`,
    enrollAdd: `/${version}/setting/enroll/add`,
    enrollDelete: `/${version}/setting/enroll/delete`,
    enrollUpdateStatus: `/${version}/setting/enroll/changeStatus`,

    term: `/${version}/setting/term/lists`,
    termDetail: `/${version}/setting/term/detail`,
    termAdd: `/${version}/setting/term/add`,
    termEdit: `/${version}/setting/term/edit`,
    termDelete: `/${version}/setting/term/delete`,

    school: `/${version}/setting/school/lists`,
    schoolDetail: `/${version}/setting/school/detail`,
    schoolAdd: `/${version}/setting/school/add`,
    schoolDelete: `/${version}/setting/school/delete`,
    schoolEdit: `/${version}/setting/school/edit`,
    schoolUpdateStatus: `/${version}/setting/school/changeStatus`,

    college: `/${version}/setting/college/lists`,
    collegeDetail: `/${version}/setting/college/detail`,
    collegeAdd: `/${version}/setting/college/add`,
    collegeDelete: `/${version}/setting/college/delete`,
    collegeEdit: `/${version}/setting/college/edit`,

    agency: `/${version}/setting/agency/lists`,
    agencyDetail: `/${version}/setting/agency/detail`,
    agencyAdd: `/${version}/setting/agency/add`,
    agencyDelete: `/${version}/setting/agency/delete`,
    agencyEdit: `/${version}/setting/agency/edit`,


    classList: `/${version}/course/course/lists`,
    classAdd: `/${version}/course/course/add`,
    classDetail: `/${version}/course/course/detail`,
    classEdit: `/${version}/course/course/edit`,
    classDelete: `/${version}/course/course/delete`,
    classUpdateStatus: `/${version}/course/course/changeStatus`,

    classOrderList: `/${version}/course/order/lists`,
    classOverview: `/${version}/course/overview/lists`,
    classCancel: `/${version}/course/order/cancel`,
    classChange: `/${version}/course/order/change`,

    classSettingList: `/${version}/course/setting/lists`,
    classSettingDetail: `/${version}/course/setting/detail`,
    classSettingAdd: `/${version}/course/setting/add`,
    classSettingEdit: `/${version}/course/setting/edit`,
    classSettingDelete: `/${version}/course/setting/delete`,

    courseDownloadTemplate: `/${version}/course/course/downloadTmp`,
    courseImportExcel: `/${version}/course/course/import`,


    recordList: `/${version}/run/record/lists`,
    recordCheck: `/${version}/run/record/check`,
    recordAppealList: `/${version}/run/record/appealList`,
    recordAppealReply: `/${version}/run/record/replyAppeal`,
    recordStatisticCollege: `/${version}/run/statistic/college`,
    recordStatisticScore: `/${version}/run/statistic/score`,
    recordStatisticTimes: `/${version}/run/statistic/times`,
    recordStatisticAuth: `/${version}/run/statistic/auth`,
    recordPointAdd: `/${version}/run/point/add`,
    recordPointDetail: `/${version}/run/point/detail`,
    recordPointEdit: `/${version}/run/point/edit`,
    recordPointDelete: `/${version}/run/point/delete`,
    recordPointList: `/${version}/run/point/lists`,
    recordRuleAdd: `/${version}/run/rule/add`,
    recordRuleDetail: `/${version}/run/rule/detail`,
    recordRuleEdit: `/${version}/run/rule/edit`,
    recordRuleDelete: `/${version}/run/rule/delete`,
    recordRuleList: `/${version}/run/rule/lists`,

    recordStepList: `/${version}/run/record/step`,
    recordDetail: `/${version}/run/record/detail`, //                          详情

    noticeList: `/${version}/notice/notice/lists`,
    noticeAdd: `/${version}/notice/notice/add`,
    noticeEdit: `/${version}/notice/notice/edit`,
    noticeDelete: `/${version}/notice/notice/delete`,
    noticeUpdateStatus: `/${version}/notice/notice/changeStatus`,
    noticeDetail: `/${version}/notice/notice/detail`,

    log: `/${version}/permission/log/lists`,
    logTypes: `/${version}/permission/log/types`,
}

export default config;
// module.exports = config;