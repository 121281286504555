import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'
import index from './index/index'
import addressSet from './index/addressSet'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    index,
    addressSet
  }
})
