
const STORAGE_LOCAL = 0, STORAGE_SESSION = 1, STORAGE_NONE = -1;
let from  = 0;
from = localStorage.getItem('token') ? STORAGE_LOCAL : sessionStorage.getItem('token') ? STORAGE_SESSION : STORAGE_NONE;

let changeFrom = function(f) {
    from = f;
}
let getFromLocal = (key)=>{
    return localStorage.getItem(key);
}
let getFromSession = (key)=>{
    return sessionStorage.getItem(key);
}
let get = function(key) {
    let r = null;
    switch (from){
        case STORAGE_NONE:
            break;
            
        case STORAGE_LOCAL:
            r = localStorage.getItem(key);
            break;
            
        case STORAGE_SESSION:
            r = sessionStorage.getItem(key);
            break;
    }
    return r;
}
let setLocal = (key, value)=>{
    localStorage.setItem(key, value);
}
let setSession = (key, value)=>{
    sessionStorage.setItem(key, value);
}
let set = function(key, value) {
    switch (from){
        case STORAGE_NONE:
            break;
            
        case STORAGE_LOCAL:
            localStorage.setItem(key, value);
            break;
            
        case STORAGE_SESSION:
            sessionStorage.setItem(key, value);
            break;
    }
}

export default{
    get,
    getFromLocal,
    getFromSession,
    set,
    setLocal,
    setSession,
    changeFrom,
    STORAGE_LOCAL,
    STORAGE_SESSION,
    STORAGE_NONE
}