<style lang="css" scoped>
.h-p-100 {
  height: 100%;
}
.w-p-100 {
  height: 100vh;
}
.g-center {
  display: -webkit-box;
  -webkit-box-align: center;
  -webkit-box-pack: center;
}
</style>
<template>
  <div class="h-p-100 w-p-100 g-center">
    <h1>
      {{ msg }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    msg: { type: String, default: '' },
  },
};
</script>
 