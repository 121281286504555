export default {
  namespaced: true,
  state:{
    // 控制 navMenu 展开 和 收起
    isCollapse:false
  },
  mutations:{
    changeIsCollapse(state){
      state.isCollapse = !state.isCollapse
    },
  }
}