import axios from "axios";
import {
  Notification
} from 'element-ui';
import storage from '../common/storage';
import config from './config.js'



const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_HOST, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 // request timeout
})

// 请求拦截器
service.interceptors.request.use((config) => {
  return config
})

// 响应拦截器
service.interceptors.response.use((config) => {
  return config
})

let request = function (params) {
  // head
  // ...
  if (storage.get('school_id')) {
    if (!params.data) {
      params.data = {}
    }
    if (!params.data.school_id && !params.noSchool) {
      params.data.school_id = storage.get('school_id');
    }
  }
  let _p = params.method == 'POST' ? {} : params.data;
  return service({
    ...params,
    headers: {
      token: storage.get('token'),
    },
    params: _p,
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'json', // default
    // `responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
  }).then((r) => {
    if (params.native) {
      params.native(r);
      return;
    }
    const { data } = r;
    let msg = '';
    if (typeof data == 'string') {
      msg = data;
    } else if (typeof data == 'object') {
      msg = data.msg
    }
    // console.log('data', r)
    if (params.successOriginal) {
      params.successOriginal(r);
      return r.data && r.data.data ? r.data.data : r.data;
    }
    if (r.status == 200 && r.data.code == 1) {
      if (params.success) {
        params.success(r.data.data);
      }
    } else {
      if (r.status != 200) {
        Notification.error({ title: 'ERROR', message: 'error code ' + r.status });
        if (params.fail)
          params.fail('error code ' + r.status);

      } else if (r.data.code != 1) {
        Notification.error({ title: 'ERROR', message: msg });
        if (params.fail)
          params.fail(msg);
      }

      return Promise.reject(msg)
    }
    return r.data && r.data.data ? r.data.data : r.data;
  }, (e) => {
    if (params.nativeError) {
      params.nativeError(e);
      return;
    }

    if (params.failOriginal) {
      params.failOriginal(e);
      return;
    }
    Notification.error({ title: 'ERROR', message: `error ${e.message || e}` });
    if (params.fail)
      params.fail(e);

    return Promise.reject(e.message)
  });
};
// console.log('init request')
// console.log(module)
// module.exports = {};
// module.exports.request = request;

let download = function (params) {
  if (storage.get('school_id')) {
    if (!params.data) {
      params.data = {}
    }
    if (!params.data.school_id) {
      params.data.school_id = storage.get('school_id');
    }
  }
  // axios.request(params.url, params.data, { token : storage.get('token'), responseType:'blob'}).
  let _p = params.method == 'POST' ? {} : params.data;
  service({
    ...params,
    headers: {
      token: storage.get('token'),
    },
    params: _p,
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'blob', // 
    // `responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
  }).then(res => {
    // console.log(res);
    const blob = new Blob([res.data]); //处理文档流
    const fileName = params.fileName;
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  })
}

let upload = (params) => {
  if (storage.get('school_id')) {
    if (!params.data) {
      params.data = {}
    }
    if (!params.data.school_id) {
      params.data.school_id = storage.get('school_id');
    }
  }

  // const flie = params.file;
  // 在这里进行一系列的校验
  // const formData = new FormData();
  // formData.append(params.key, flie);
  // params.data[params.key] = formData;
  let _p = params.method == 'POST' ? {} : params.data;
  return new Promise((resolve, reject) => {

    service({
      ...params,
      headers: {
        token: storage.get('token'),
        'Content-Type': 'multipart/form-data'
      },
      params: _p,
      // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
      responseType: 'json', // 
      // `responseEncoding` indicates encoding to use for decoding responses
      // Note: Ignored for `responseType` of 'stream' or client-side requests
      responseEncoding: 'utf8', // default
    }).then((r) => {


      if (params.successOriginal) {
        params.successOriginal(r);
        return r;
      }
      if (r.status == 200 && r.data.code == 1) {
        if (params.success) {
          params.success(r.data.data);
        }
        resolve([true, r.data && r.data.data ? r.data.data : r.data ? r.data : r])
      } else {
        if (r.status != 200) {
          Notification.error({
            title: 'ERROR',
            message: 'error code ' + r.status
          });
          if (params.fail)
            params.fail('error code ' + r.status);
        } else if (r.data.code != 1) {
          Notification.error({
            title: 'ERROR',
            message: r.data.msg
          });
          if (params.fail)
            params.fail(r.data.msg);
        }
        resolve([false, r.data && r.data.data ? r.data.data : r.data ? r.data : r])
      }
    }, (e) => {

      if (params.failOriginal) {
        params.failOriginal(e);
        return r;
      }
      Notification.error({ title: 'ERROR', message: 'error ' + e });
      if (params.fail) {
        params.fail(e);
      }

      const { data } = e.response || {};
      const { message } = data || {}
      reject(message || data)
    })

  })
}

export default {
  request,
  download,
  upload
}