import Vue from 'vue'
import VueRouter from "vue-router"
import storage from '../common/storage';
import {
  Notification
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter)

// redirect: '/login'
const routes = [
  { name: 'www', path: '/', component: () => import('../views/www/index') },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/login/Login.vue'),
  },
  {
    name: 'manage',
    path: '/index',
    component: () => import('@/views/index/Index.vue'),
    children: [
      {
        path: '/',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/index/home/Home.vue')
      },
      {
        path: 'stuManage',
        name: 'stuManage',
        component: () => import('@/views/index/stuManage/StuManage.vue')
      },
      {
        path: 'teaManage',
        name: 'teaManage',
        component: () => import('@/views/index/teaManage/TeaManage.vue')
      },
      {
        path: 'courManage',
        name: 'courManage',
        component: () => import('@/views/index/courManage/CourManage.vue')
      },
      {
        path: 'runManage',
        name: 'runManage',
        component: () => import('@/views/index/runManage/RunManage.vue')
      },
      {
        path: 'collNotice',
        name: 'collNotice',
        component: () => import('@/views/index/collNotice/CollNotice.vue')
      },
      {
        path: 'log',
        name: 'log',
        component: () => import('@/views/index/log/Log.vue')
      },
      {
        path: 'basisSet',
        name: 'basisSet',
        component: () => import('@/views/index/basisSet/BasisSet.vue')
      },
      {
        path: 'systManage',
        name: 'systManage',
        component: () => import('@/views/index/systManage/SystManage.vue')
      }
    ]
  }
]
console.log('router', routes)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode:  'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whileList = ['/']
// 导航守卫
router.beforeEach((to, from, next) => {
  // console.log('to', to)
  document.title = to.path=='/' ? '咕鸽运动校园潮跑官网' : '高校智慧体育数字管理平台'
  let token = storage.get('token');
  // 当 token 不存在 并且 访问页面为除 login 外的页面
  if (!whileList.includes(to.path) && !token && to.name !== 'login') {
    Notification.error({ title: '警告', message: '您还未登录，请先登录！', })
    next('/login')
  } else {
    next()
  }
})

export default router