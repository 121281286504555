export default {
    namespaced: true,
    actions: {
        getLongitude({ commit }, longitude) {
            commit('GETLONGITUDE', longitude)
        },
        getLatitude({ commit }, latitude) {
            commit('GETLATITUDE', latitude)
        },
        getAddress({ commit }, address) {
            commit('GETADDRESS', address)
        },
        getCoordinate({ commit }, coordinate) {
            commit('GETCOORDINATE', coordinate)
        },
        getDrawer({ commit }, drawer) {
            commit('GETDRAWER', drawer)
        },
    },
    mutations: {
        GETLONGITUDE(state, longitude) {
            state.longitude = longitude
        },
        GETLATITUDE(state, latitude) {
            state.latitude = latitude
        },
        GETADDRESS(state, address) {
            state.address = address
        },
        GETCOORDINATE(state, coordinate) {
            state.coordinate = coordinate
        },
        GETDRAWER(state, drawer) {
            state.drawer = drawer
        },
    },
    state: {
        longitude: null,
        latitude: null,
        address: null,
        coordinate: null,
        drawer: null
    },
    getters: {
        // coordinate(state) {
        //     return state.longitude  + ',' + state.latitude;
        // }
    }
}