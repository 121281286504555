let list = {
    'INDEX': 'index',
    'STUDENT_MANAGE': 'student_manage',
    'STUDENT_DELETE': 'student_delete',
    'STUDENT_IMPORT': 'student_import',
    'STUDENT_EXPORT': 'student_export',
    'STUDENT_DOWNLOAD_TMPELETE': 'student_download_tmpelete',
    'STUDENT_CANCEL_AUTH': 'student_cancel_auth',
    'TEACHER_MANAGE': 'teacher_manage',
    'TEACHER_DELETE': 'teacher_delete',
    'TEACHER_IMPORT': 'teacher_import',
    'TEACHER_EXPORT': 'teacher_export',
    'TEACHER_DOWNLOAD_TMPELETE': 'teacher_download_tmpelete',
    'TEACHER_CANCEL_AUTH': 'teacher_cancel_auth',
    'COURSE_MANAGE': 'course_manage',
    'COURSE_LIST': 'course_list',
    'COURSE_ADD': 'course_add',
    'COURSE_EDIT': 'course_edit',
    'COURSE_DELETE': 'course_delete',
    'COURSE_UP': 'course_up',
    'COURSE_DOWN': 'course_down',
    'COURSE_ORDER': 'course_order',
    'COURSE_ORDER_EXPORT': 'course_order_export',
    'COURSE_OVERVIEW': 'course_overview',
    'COURSE_OVERVIEW_EXPORT': 'course_overview_export',
    'COURSE_SETTINGS': 'course_settings',
    'COURSE_SETTINGS_ADD': 'course_settings_add',
    'COURSE_SETTINGS_EDIT': 'course_settings_edit',
    'COURSE_SETTINGS_DELETE': 'course_settings_delete',
    'RUN_MANAGE': 'run_manage',
    'RUN_STUDENT_LIST': 'run_student_list',
    'RUN_STUDENT_EXPORT': 'run_student_export',
    'RUN_RECORD_LIST': 'run_record_list',
    'RUN_RECORD_EXPORT': 'run_record_export',
    'RUM_COLLEGE_LIST': 'run_colleage_list',
    'RUM_COLLEGE_LIST_EXPORT': 'run_colleage_export',
    'RUN_STATISTIC': 'run_statistic',
    'RUN_POINT_SETTINGS': 'run_point_settings',
    'RUN_POINT_SETTINGS_ADD': 'run_point_settings_add',
    'RUN_POINT_SETTINGS_EDIT': 'run_point_settings_edit',
    'RUN_POINT_SETTINGS_DELETE': 'run_point_settings_delete',
    'RUN_RULE_SETTINGS': 'run_rule_settings',
    'RUN_RULE_SETTINGS_ADD': 'run_rule_settings_add',
    'RUN_RULE_SETTINGS_EDIT': 'run_rule_settings_edit',
    'RUN_RULE_SETTINGS_DELETE': 'run_rule_settings_delete',

    'RUN_EFFECTIVE_TIMES': 'run_effective_times',
    'RUN_EFFECTIVE_TIMES_DELETE': 'run_effective_times_delete',
    'RUN_EFFECTIVE_TIMES_ADD': 'run_effective_times_add',
    'RUN_EFFECTIVE_TIMES_EDIT': 'run_effective_times_edit',

    'NOTIFICATION_SETTINGS': 'notification_settings',
    'NOTIFICATION_ADD': 'notification_add',
    'NOTIFICATION_EDIT': 'notification_edit',
    'NOTIFICATION_DELETE': 'notification_delete',
    'NOTIFICATION_CANCEL': 'notification_cancel',
    'NOTIFICATION_PUBLISH': 'notification_publish',
    'BASIC_SETTINGS': 'basic_settings',
    'ENROLL_LIST': 'enroll_list',
    'ENROLL_ADD': 'enroll_add',
    'ENROLL_EDIT': 'enroll_edit',
    'ENROLL_DELETE': 'enroll_delete',
    'ENROLL_GRADUATION': 'enroll_graduation',
    'ENROLL_NOT_GRADUATION': 'enroll_not_graduation',
    'TERM_LIST': 'term_list',
    'TERM_ADD': 'term_add',
    'TERM_EDIT': 'term_edit',
    'TERM_DELETE': 'term_delete',
    'COLLEGE_LIST': 'college_list',
    'COLLEGE_ADD': 'college_add',
    'COLLEGE_EDIT': 'college_edit',
    'COLLEGE_DELETE': 'college_delete',
    'ORGAN_LIST': 'organ_list',
    'ORGAN_ADD': 'organ_add',
    'ORGAN_EDIT': 'organ_edit',
    'ORGAN_DELETE': 'organ_delete',
    'SYS_SETTINGS': 'sys_settings',
    'ACCOUNT_LIST': 'account_list',
    'ACCOUNT_ADD': 'account_add',
    'ACCOUNT_EDIT': 'account_edit',
    'ACCOUNT_DELETE': 'account_delete',
    'ACCOUNT_CHANGE_MOBILE': 'account_change_mobile',
    'ACCOUNT_CHANGE_PWD': 'account_change_pwd',
    'ROLE_LIST': 'role_list',
    'ROLE_ADD': 'role_add',
    'ROLE_EDIT': 'role_edit',
    'ROLE_DELETE': 'role_delete',
    'ROLE_PERMISSION_CONFIG': 'role_permission_config',
}


let permissions = [
    {
        key: 'index',
        name: '首页',
        model: 'run',
        controller: 'statistic',
        method: 'auth',
        children: []

    },
    {
        key: 'student_manage',
        name: '学生管理',
        model: 'student',
        controller: 'student',
        method: 'lists',
        children: [
            {
                key: 'student_delete',
                name: '删除',
                method: 'delete'
            },
            {
                key: 'student_import',
                name: '导入',
                method: 'import'
            },
            {
                key: 'student_export',
                name: '导出',
                method: 'export'
            },
            {
                key: 'student_download_tmpelete',
                name: '下载模板',
                method: 'downloadTmp'
            },
            {
                key: 'student_cancel_auth',
                name: '取消认证',
                method: 'changeStatus'
            }
        ]

    },
    {
        key: 'teacher_manage',
        name: '教师管理',
        model: 'teacher',
        controller: 'teacher',
        method: 'lists',
        children: [
            {
                key: 'teacher_delete',
                name: '删除',
                method: 'delete',
            },
            {
                key: 'teacher_import',
                name: '导入',
                method: 'import',
            },
            {
                key: 'teacher_export',
                name: '导出',
                method: 'export',
            },
            {
                key: 'teacher_download_tmpelete',
                name: '下载模板',
                method: 'downloadTmp',
            },
            {
                key: 'teacher_cancel_auth',
                name: '取消认证',
                method: 'changeStatus',
            }
        ]

    }, {
        key: 'course_manage',
        name: '选课管理',
        model: 'course',
        children: [],
        pages: [
            {
                key: 'course_list',
                name: '课程管理',
                controller: 'course',
                method: 'lists',
                children: [
                    {
                        key: 'course_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'course_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'course_delete',
                        name: '删除',
                        method: 'delete',
                    },
                    {
                        key: 'course_up',
                        name: '上架',
                        method: 'changeStatus',
                    },
                    {
                        key: 'course_down',
                        name: '下架',
                        method: 'changeStatus',
                    },
                ]
            },
            {
                key: 'course_order',
                name: '课程明细',
                controller: 'order',
                method: 'lists',
                children: [
                    {
                        key: 'course_order_export',
                        name: '导出',
                        method: 'export',
                    },

                ]
            },
            {
                key: 'course_overview',
                name: '课程概览',
                controller: 'overview',
                method: 'lists',
                children: [
                    {
                        key: 'course_overview_export',
                        name: '导出',
                        export: 'export',
                    },

                ]
            },
            {
                key: 'course_settings',
                name: '选课设置',
                controller: 'setting',
                method: 'lists',
                children: [
                    {
                        key: 'course_settings_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'course_settings_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'course_settings_delete',
                        name: '删除',
                        method: 'delete',
                    },

                ]
            },

        ]

    },
    {
        key: 'run_manage',
        name: '跑步管理',
        model: 'run',
        children: [],
        pages: [
            {
                key: 'run_student_list',
                name: '学生数据',
                model: 'student',
                controller: 'student',
                method: 'lists',
                children: [
                    {
                        key: 'run_student_export',
                        name: '导出',
                        method: 'export'
                    },
                ]
            },
            {
                key: 'run_record_list',
                name: '跑步明细',
                controller: 'record',
                method: 'lists',
                children: [
                    {
                        key: 'run_record_export',
                        name: '导出',
                        method: 'export'
                    },
                ]
            },
            {
                key: 'run_colleage_list',
                name: '学校院系概况',
                controller: 'statistic',
                method: 'college',
                children: [
                    {
                        key: 'run_colleage_export',
                        name: '导出',
                        method: 'export'
                    },
                ]
            },
            {
                key: 'run_statistic',
                name: '跑步分析',
                controller: 'statistic',
                method: 'times'
            },
            {
                key: 'run_point_settings',
                name: '打卡地点设置',
                controller: 'point',
                method: 'lists',
                children: [
                    {
                        key: 'run_point_settings_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'run_point_settings_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'run_point_settings_delete',
                        name: '删除',
                        method: 'delete',
                    },

                ]
            },
            {
                key: 'run_rule_settings',
                name: '跑步规则设置',
                controller: 'rule',
                method: 'lists',
                children: [
                    {
                        key: 'run_rule_settings_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'run_rule_settings_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'run_rule_settings_delete',
                        name: '删除',
                        method: 'delete',
                    },

                ]
            },
            {
                key: 'run_effective_times',
                name: '有效次数管理',
                controller: 'rule',
                method: 'lists',
                children: [
                    { key: 'run_effective_times_add', name: '添加', method: 'add', },
                    { key: 'run_effective_times_edit', name: '编辑', method: 'edit', },
                    { key: 'run_effective_times_delete', name: '删除', method: 'delete', },
                ]
            },
        ]

    },
    {
        key: 'notification_settings',
        name: '学校通知',
        model: 'notice',
        controller: 'notice',
        method: 'lists',
        children: [
            {
                key: 'notification_add',
                name: '添加',
                method: 'add',
            },
            {
                key: 'notification_edit',
                name: '编辑',
                method: 'edit',
            },
            {
                key: 'notification_delete',
                name: '删除',
                method: 'delete',
            },
            {
                key: 'notification_cancel',
                name: '取消发布',
                method: 'changeStatus',
            },
            {
                key: 'notification_publish',
                name: '发布',
                method: 'changeStatus',
            },

        ]
    },
    {
        key: 'basic_settings',
        name: '基础设置',
        model: 'setting',
        children: [],
        pages: [
            {
                key: 'enroll_list',
                name: '学年管理',
                controller: 'enroll',
                method: 'lists',
                children: [
                    {
                        key: 'enroll_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'enroll_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'enroll_delete',
                        name: '删除',
                        method: 'delete',
                    },
                    {
                        key: 'enroll_graduation',
                        name: '设置毕业',
                        method: 'changeStatus',
                    },
                    {
                        key: 'enroll_not_graduation',
                        name: '取消毕业',
                        method: 'changeStatus',
                    },
                ]
            },
            {
                key: 'term_list',
                name: '学期设置',
                controller: 'term',
                method: 'lists',
                children: [
                    {
                        key: 'term_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'term_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'term_delete',
                        name: '删除',
                        method: 'delete',
                    },
                ]
            },
            {
                key: 'college_list',
                name: '院系设置',
                controller: 'college',
                method: 'lists',
                children: [
                    {
                        key: 'college_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'college_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'college_delete',
                        name: '删除',
                        method: 'delete',
                    },
                ]
            },
            {
                key: 'organ_list',
                name: '机构设置',
                controller: 'agency',
                method: 'lists',
                children: [
                    {
                        key: 'organ_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'organ_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'organ_delete',
                        name: '删除',
                        method: 'delete',
                    },

                ]
            },
        ]

    },
    {
        key: 'sys_settings',
        name: '系统设置',
        model: 'permission',
        children: [],
        pages: [
            {
                key: 'account_list',
                name: '账号管理',
                controller: 'user',
                method: 'lists',
                children: [
                    {
                        key: 'account_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'account_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'account_delete',
                        name: '删除',
                        method: 'delete',
                    },
                    {
                        key: 'account_change_mobile',
                        name: '修改手机号码',
                        method: 'changeMobile',
                    },
                    {
                        key: 'account_change_pwd',
                        name: '修改密码',
                        method: 'changePassword',
                    },
                ]
            },
            {
                key: 'role_list',
                name: '角色权限',
                controller: 'role',
                method: 'lists',
                children: [
                    {
                        key: 'role_add',
                        name: '添加',
                        method: 'add',
                    },
                    {
                        key: 'role_edit',
                        name: '编辑',
                        method: 'edit',
                    },
                    {
                        key: 'role_delete',
                        name: '删除',
                        method: 'delete',
                    },
                    {
                        key: 'role_permission_config',
                        name: '角色权限',
                        method: 'edit',
                    },
                ]
            },
        ]

    },
]

import storage from './storage.js'
let hasPermission = (permission) => {
    if (!permission) {
        return true;
    }
    let menus = JSON.parse(storage.get('userInfo')).menus;
    let has = false;
    menus.forEach(m => {
        if (m.name == permission) {
            has = true;
        }
    });
    // TODO delete next line
    // has = true
    // console.log(permission, has)
    return has;
}
export default {
    permissions,
    hasPermission,
    list
}