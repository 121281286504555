// 必须引入封装好的 axios 实例方法,才可发请求
// import { reqLogin, reqRegister } from '../../api/index'
export default {
    namespaced: true,
    actions: {
    },
    mutations: {
    },
    state: {
    },
    getters: {
    }
}
