import Vue from 'vue'
// 内置过渡动画样式
import 'element-ui/lib/theme-chalk/index.css';
// 内置过渡动画
// import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import {
    Loading,
    Container,
    Aside,
    Header,
    Main,
    Menu,
    MenuItem,
    Tabs,
    TabPane,
    Input,
    Button,
    Checkbox,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Message,
    FormItem,
    Form,
    Select,
    Option,
    Table,
    TableColumn,
    Row,
    Col,
    Pagination,
    Drawer,
    Radio,
    RadioGroup,
    Upload,
    Popconfirm,
    MessageBox,
    DatePicker,
    Dialog,
    TimePicker,
    TimeSelect,
    Card,
    Switch,
    Autocomplete,
    Notification,
    Tooltip,
    Popover,
    Link, Divider, CheckboxGroup, CheckboxButton, Footer, Image
} from 'element-ui'

// 挂载组件
Vue.use(Footer)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Divider)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Upload)
Vue.use(Popconfirm)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(Card)
Vue.use(Switch)
Vue.use(Autocomplete)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Link)

Vue.prototype.$notify = Notification;
Vue.prototype.$confirm = MessageBox.confirm;