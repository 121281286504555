import Vue from 'vue'
import App from './App.vue'
import PlatformLoginError from './PlatformLoginError.vue';
import router from './router'
import store from './store'

import storage from './common/storage'
Vue.prototype.$storage = storage;
// 导入 mock 和 axios 文件
// import '@/mock/index'
// import '@/api/axios'
// 引入element-ui
import './plugins/element-ui.js'
// // 引入amap
// import './plugins/amap.js'

// 初始化样式,引入样式顺序要从上往下,越下面的权重越高
import './assets/css/base.css'

// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

// 接口配置
import config from './api/config.js';
Vue.prototype.ApiConfig = config;
// 接口函数
import request from './api/request.js';
Vue.prototype.Api = request;

Vue.prototype.$getUserInfo = () => {
  return JSON.parse(storage.get('userInfo'));
};

import moment from './common/moment.js';
Vue.prototype.moment = moment;
Vue.prototype.VERSION = process.env.VERSION;

import permission from './common/permission';
Vue.prototype.$permission = permission;

import JsonExcel from './components/common/JsonExcel.vue';
Vue.component('downloadExcel', JsonExcel);

const init = async () => {
  const parseUrlQueryString = (search) => {
    let queryString = search;

    if (!search) {
      queryString = window.location.search.substring(1);
    }

    const obj = {};
    if (queryString) {
      queryString.split('&').forEach((kv) => {
        const [key, value] = kv.split('=');
        obj[key] = decodeURIComponent(value);
      });
      console.log(obj);
    }
    return obj;
  }

  const findPath = (menus = []) => {
    let navList = [
      { name: "首页", path: "home", p: permission.list.INDEX, },
      { name: "学生管理", path: "stuManage", p: permission.list.STUDENT_MANAGE, },
      { name: "教师管理", path: "teaManage", p: permission.list.TEACHER_MANAGE, },
      { name: "选课管理", path: "courManage", p: permission.list.COURSE_MANAGE, },
      { name: "跑步管理", path: "runManage", p: permission.list.RUN_MANAGE, },
      { name: "学校通知", path: "collNotice", p: permission.list.NOTIFICATION_SETTINGS, },
      { name: "基础设置", path: "basisSet", p: permission.list.BASIC_SETTINGS, },
      // { name: "系统管理", path: "systManage", p: permission.list.SYS_SETTINGS, },
    ];
    let judgeIndex = (r) => {
      let find = ''
      navList.forEach((menu) => {
        r.menus.forEach((item) => {
          if (menu.p == item.name && !find) {
            find = menu.path
          }
        })
      })
      return find
    }
    const itemNames = menus.filter((p) => !!p.name).map((p) => p.name && p.name.toLowerCase())
    const res = navList.find((p) => itemNames.includes(p.p && p.p.toLowerCase())) || {};
    console.log('res', res);
    return res.path;
  }

  const qs = parseUrlQueryString();
  const { token } = qs;

  Vue.prototype._SUB_SYSTEM_ = false;
  let newPath;

  let platformLoginError = '';

  if (token) {
    Vue.prototype._SUB_SYSTEM_ = true;

    const { protocol, host, pathname } = window.location;
    const href = `${protocol}//${host}${pathname}`;
    console.log('href:', href, window.location.href);
    try {
      const ui = await new Promise((resolve, reject) => {
        // remove token
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('school_id');
        sessionStorage.removeItem('school_id');

        request.request({
          method: 'POST',
          url: 'v1/permission/loginByPlatformToken', // loginByPlatformToken   loginByGovToken
          data: { token },
          native: (res) => {
            const { status } = res;
            if (status == 200) {
              const { data, code, msg } = res.data;
              if (code == 1) {
                //
                resolve(data)
              } else {
                reject(msg)
              }
              console.log('token info res', res);
            }
          },
          nativeError: (err) => {
            console.log('token login error', err)
            reject(err)
          }
        })
      })
      window._UI_ = ui;
      console.log('获取用户信息1', pathname, ui);
      storage.changeFrom(storage.STORAGE_LOCAL);
      storage.setLocal("token", ui.token);
      storage.setLocal("userInfo", JSON.stringify(ui));
      const path = findPath(ui.menus);
      newPath = path;
      const { search, } = window.location;
      window.history.replaceState({}, '', `${search}/#/index/${path}`);

    } catch (ex) {
      console.log('platform login error', ex);
      platformLoginError = ex;
    }
  }

  new Vue({
    router,
    store,
    render: h => {
      if (platformLoginError) {
        return h(PlatformLoginError, { props: { msg: platformLoginError } })
      }
      return h(App)
    },
    created () {
      if (token) {

        // this.$router.push('/index${path}')
      }
    },
    mounted () {
      console.log('main mounted');
    }
  }).$mount('#app')

};

init();